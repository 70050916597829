import React, { Component } from "react";
import routes from "./routes";
import ScrollButton from './components/ScrollToTop/ScrollButton';
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";

// Import Scss
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import Index1 from "./pages/Index1/Index1";

class App extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
    render() {
        return (
          <React.Fragment>
             
          <Router>
            <Switch>
              {routes.map((route, idx) => (
                <Route path={route.path} component={Index1} key={idx} />
              ))}
            </Switch>
          </Router>
          <ScrollButton />
        </React.Fragment>
       
      );
    }
  }
  
  export default withRouter(App);